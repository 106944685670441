import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f4bbe58"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-wrap flex-stack my-5 position-relative" }
const _hoisted_2 = { class: "fs-2 fw-bold my-2" }
const _hoisted_3 = { class: "d-flex flex-wrap my-1 align-items-center ms-auto" }
const _hoisted_4 = { class: "nav nav-pills me-5" }
const _hoisted_5 = { class: "nav-item m-0" }
const _hoisted_6 = { class: "svg-icon svg-icon-2" }
const _hoisted_7 = { class: "nav-item m-0" }
const _hoisted_8 = { class: "svg-icon svg-icon-2" }
const _hoisted_9 = { class: "nav-item m-0" }
const _hoisted_10 = { class: "svg-icon svg-icon-2 target-gant" }
const _hoisted_11 = { class: "" }
const _hoisted_12 = { class: "flex justify-center tab-content" }
const _hoisted_13 = {
  key: 0,
  class: "tab-pane fade show active"
}
const _hoisted_14 = {
  key: 0,
  class: "kanban"
}
const _hoisted_15 = { class: "h-100" }
const _hoisted_16 = { class: "h-100" }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_FilterTicket = _resolveComponent("FilterTicket")!
  const _component_Target = _resolveComponent("Target")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_TargetsTabel = _resolveComponent("TargetsTabel")!
  const _component_Gantchart = _resolveComponent("Gantchart")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t("tickets")), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("ul", _hoisted_4, [
          _createElementVNode("li", _hoisted_5, [
            _createElementVNode("button", {
              class: "btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary active me-3",
              "data-bs-toggle": "tab",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeTabs('isKanban')))
            }, [
              _createElementVNode("span", _hoisted_6, [
                _createVNode(_component_inline_svg, { src: "svg/icons/hamburger.svg" })
              ])
            ])
          ]),
          _createElementVNode("li", _hoisted_7, [
            _createElementVNode("button", {
              class: "btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary me-3",
              "data-bs-toggle": "tab",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeTabs('isListTicket')))
            }, [
              _createElementVNode("span", _hoisted_8, [
                _createVNode(_component_inline_svg, { src: "svg/icons/squares-icon.svg" })
              ])
            ])
          ]),
          _createElementVNode("li", _hoisted_9, [
            _createElementVNode("button", {
              class: "btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary",
              "data-bs-toggle": "tab",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeTabs('isGant')))
            }, [
              _createElementVNode("span", _hoisted_10, [
                _createVNode(_component_inline_svg, { src: "svg/icons/gant-tab-icon.svg" })
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("button", {
            class: "btn-filter",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isVisible = !_ctx.isVisible))
          }, " Filter ")
        ])
      ]),
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          (_ctx.isVisible)
            ? _withDirectives((_openBlock(), _createBlock(_component_FilterTicket, { key: 0 }, null, 512)), [
                [_directive_click_outside, _ctx.closeFilter]
              ])
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_12, [
      (_ctx.typeTabs === 'isKanban')
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            (_ctx.ticketsData)
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ticketsData, (list, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: ""
                    }, [
                      _createElementVNode("div", _hoisted_15, [
                        _createElementVNode("p", {
                          class: _normalizeClass(["text-gray-900 target-title fs-6 fw-bold pb-4", `color-border-bt-${list.order}`])
                        }, _toDisplayString(_ctx.$t(list.name)), 3),
                        _createElementVNode("div", _hoisted_16, [
                          _createVNode(_component_draggable, {
                            class: "h-100",
                            modelValue: list.tickets,
                            "onUpdate:modelValue": ($event: any) => ((list.tickets) = $event),
                            animation: 200,
                            group: "list",
                            "item-key": list.name,
                            onChange: (e) => _ctx.handelChangeTickets(e, list)
                          }, {
                            item: _withCtx(({ element }) => [
                              _createElementVNode("div", null, [
                                _createVNode(_component_Target, {
                                  ticketClass: "mb-xl-8 ms-1",
                                  title: element.title,
                                  prefix: element.key,
                                  assign: element.assignedUser,
                                  class: "mt-3 cursor-move"
                                }, null, 8, ["title", "prefix", "assign"])
                              ])
                            ]),
                            _: 2
                          }, 1032, ["modelValue", "onUpdate:modelValue", "item-key", "onChange"])
                        ])
                      ])
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.typeTabs === 'isListTicket')
        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
            _createVNode(_component_TargetsTabel)
          ]))
        : _createCommentVNode("", true),
      (_ctx.ticketsLinks && _ctx.ticketsLinks.data && _ctx.typeTabs === 'isGant')
        ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
            _createVNode(_component_Gantchart, { tasksData: _ctx.ticketsLinks }, null, 8, ["tasksData"])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}