
import { defineComponent, ref } from "vue";
import useTranslate from "@/core/composable/useTranslate";
/* eslint-disable  @typescript-eslint/no-explicit-any */
interface Filter {
  skills: Array<string>;
  status: Array<string>;
  type: Array<string>;
  users: Array<string>;
  projects: Array<string>;
  date: any;
}

export default defineComponent({
  name: "filter-ticket",
  components: {},
  setup() {
    const { translate } = useTranslate();
    const data = ref<Filter>({
      skills: [],
      status: [],
      type: [],
      users: [],
      projects: [],
      date: "",
    });
    const optionsSkills = ref([
      {
        label: "javascript",
        value: "javascript",
      },
      {
        label: "react",
        value: "react",
      },
      {
        label: "nodejs",
        value: "nodejs",
      },
    ]);
    const optionsStatut = ref([
      {
        label: `In Progress`,
        value: "progress",
      },
      {
        label: `In Code Review`,
        value: "In Code Review",
      },
      {
        label: `Feedback`,
        value: "Feedback",
      },
      {
        label: `To Do`,
        value: "To Do",
      },
      {
        label: `Done`,
        value: "Done",
      },
    ]);
    const optionsTypes = ref([
      {
        name: "Page",
        value: "Page",
      },
      {
        name: "Section",
        value: "Section",
      },
      {
        name: "Action",
        value: "Action",
      },
    ]);
    const optionsUsers = ref([
      {
        name: "ahmed",
      },
      {
        name: "achref",
      },
      {
        name: "aymen",
      },
    ]);
    const optionsProjects = ref([
      {
        name: "DevFactory",
      },
      {
        name: "Apollo",
      },
      {
        name: "Studio",
      },
    ]);
    const restForm = () => {
      data.value = {
        skills: [],
        status: [],
        type: [],
        users: [],
        projects: [],
        date: "",
      };
    };

    const submitFilterData = async () => {
      await console.log(data.value, "datavalue");
    };

    return {
      optionsSkills,
      optionsStatut,
      optionsTypes,
      optionsUsers,
      optionsProjects,
      data,
      restForm,
      translate,
      submitFilterData,
    };
  },
});
