
import { defineComponent, onMounted, computed, ref } from "vue";

import draggable from "vuedraggable";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/config";
import { supabase } from "@/service/SupabaseService";
import { definitions } from "@/types/supabase";
import NotifService from "@/core/services/NotifService";
import { useRoute } from "vue-router";
import { setLinksTicket } from "@/core/helpers/ticket";
import { setStatusTicket } from "@/core/helpers/ticket";
import FilterTicket from "./parts/FilterTicket.vue";
import Target from "./parts/Target.vue";
import TargetsTabel from "./parts/TargetsTabel.vue";
import Gantchart from "./parts/Gantchart.vue";

export default defineComponent({
  name: "targets",
  components: {
    draggable,
    FilterTicket,
    Target,
    TargetsTabel,
    Gantchart,
  },

  setup() {
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    const store = useStore();
    const typeTabs = ref("");
    const route = useRoute();
    const platformID = route.params["id"] as string;
    const listTickets = ref<any>([]);
    const isVisible = ref(false);

    const changeTabs = async (id: string) => {
      if (id === "isGant") setLinksTicket(parseInt(platformID));
      else if (id === "isKanban")
        listTickets.value = await setStatusTicket(parseInt(platformID));

      typeTabs.value = id;
    };
    const ticketsLinks = computed(() => {
      return store.getters.listTicketLinks;
    });
    const closeFilter = (event) => {
      event.target.closest(".el-popper") ? "" : (isVisible.value = false);
    };

    const handelChangeTickets = (e, list) => {
      if (e.added) {
        supabase
          .from<definitions["tickets"]>("tickets")
          .update({ statusId: list.id })
          .match({ id: e.added.element.id })
          .then(({ error }) => {
            if (error) NotifService.displayErrorAlert(error.message);
          });
      }
    };
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    const ticketsData = ref<any>([]);
    // GET TICKETS FROM SUPABASE
    const getStatusTickets = async () => {
      const response = await supabase
        .from("ticket_statuses")
        .select("id , name , order");

      return response.data;
    };
    // ADD LIST TICKETS THAT COME FROM API TO DATA THAT CAME FROM SUPABASE
    const addListTickets = async (data) => {
      listTickets.value = await setStatusTicket(parseInt(platformID));
      data.sort((a, b) => a.order - b.order);

      for (let i = 0; i < data.length; i++) {
        data[i].tickets = [];
        for (let j = 0; j < listTickets.value.length; j++) {
          if (listTickets.value[j]?.order == data[i].order) {
            data[i].tickets = listTickets.value[j].tickets;
          }
        }
      }
      return data;
    };
    // INIT
    getStatusTickets()
      .then((data) => addListTickets(data))
      .then((res) => (ticketsData.value = res));

    onMounted(() => {
      setCurrentPageBreadcrumbs("Platforms", ["Pages", "Profile"]);
      typeTabs.value = "isKanban";
    });

    return {
      ticketsLinks,
      handelChangeTickets,
      changeTabs,
      typeTabs,
      ticketsData,
      isVisible,
      closeFilter,
    };
  },
});
