
import { defineComponent } from "vue";
import Badge from "@/components/shared/Badge.vue";
import Svg from "@/components/shared/Svg.vue";
import Avatar from "@/components/shared/Avatar.vue";

export default defineComponent({
  components: { Badge, Svg, Avatar },
  props: {
    ticketClass: String,
    prefix: String,
    title: String,
    assign: Object,
  },
});
