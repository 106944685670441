import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const setParamsTicket = async (data: any) => {
  await store.dispatch(Actions.FETCH_LIST_ALL_TICKETS, {
    data: data,
  });
};

export const setLinksTicket = async (data: any) => {
  await store.dispatch(Actions.FETCH_LIST_TICKETS_LINKS, {
    data: data,
  });
};

export const setStatusTicket = (data: any) => {
  return store.dispatch(Actions.FETCH_LIST_TICKETS_STATUS, {
    data: data,
  });
};
