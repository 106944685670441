
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    src: String,
    size: {
      type: Number,
      default: 2,
    },
  },
});
