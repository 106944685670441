<template>
  <div class="gantt" ref="gantt" id="gant_chart"></div>
</template>

<script>
import "dhtmlx-gantt";
// import { defineComponent } from "vue";

import { setModal } from "@/core/helpers/config";
let gantt;

export default {
  name: "gantt",
  props: {
    tasksData: Object,
  },
  methods: {
    $_initGanttEvents: function () {
      gantt = window.gantt;
      if (!window.gantt.$_eventsInitialized) {
        gantt.attachEvent("onTaskSelected", (id) => {
          let task = gantt.getTask(id);
          this.$emit("task-selected", task);
        });

        gantt.attachEvent("onTaskIdChange", (id, new_id) => {
          if (gantt.getSelectedId() == new_id) {
            let task = gantt.getTask(new_id);
            this.$emit("task-selected", task);
          }
        });

        gantt.$_eventsInitialized = true;
      }
    },

    $_initDataProcessor: function () {
      if (!gantt.$_dataProcessorInitialized) {
        gantt.createDataProcessor((entity, action, data, id) => {
          this.$emit(`${entity}-updated`, id, action, data);
        });

        gantt.$_dataProcessorInitialized = true;
      }
    },
  },

  mounted: function () {
    this.$_initGanttEvents();
    gantt.config.xml_date = "%Y-%m-%d";
    gantt.config.columns = [
      { name: "text", label: "Task name", width: "150", tree: true },
      { name: "start_date", label: "Start time", align: "center" },
      { name: "duration", label: "Duration", align: "center" },
      { name: "add", label: "", width: 44 },
    ];

    gantt.showLightbox = function () {
      return;
    };
    gantt.attachEvent("onTaskCreated", function () {
      setModal("KTCreateNewTicket");
      return false;
    });
    gantt.init(this.$refs.gantt);
    gantt.parse(this.tasksData);
    this.$_initDataProcessor();
  },
};
</script>

<style>
@import "~dhtmlx-gantt/codebase/dhtmlxgantt.css";
.gantt {
  min-height: 500px;
}
@media screen and (max-width: 1280px) {
  .gantt {
    width: 100%;
  }
}
.gantt_side_content,
.gantt_task_content,
.gantt_task_progress {
  border-radius: 4px;
}
.gantt_task_line {
  border: none;
  background: #7239ea;
}
.gantt_task_line.gantt_selected {
  box-shadow: 0 0 5px #7239ea;
}
.gantt_add,
.gantt_grid_head_add {
  background-image: url("/svg/icons/add-icon.svg");
}
.gantt_grid_data .gantt_cell,
.gantt_grid_scale,
.gantt_grid_head_cell,
.gantt_task .gantt_task_scale .gantt_scale_cell,
.gantt_task .gantt_task_scale {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8rem;
  color: #000000 !important;
}
.gantt_tree_icon.gantt_folder_open {
  background-image: none;
}
</style>
