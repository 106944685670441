import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "card-body d-flex flex-column py-2 px-4" }
const _hoisted_2 = { class: "mb-4 fw-normal mt-3" }
const _hoisted_3 = { class: "d-flex align-items-center" }
const _hoisted_4 = { class: "ms-1" }
const _hoisted_5 = { class: "ms-1" }
const _hoisted_6 = {
  key: 0,
  class: "ms-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Badge = _resolveComponent("Badge")!
  const _component_Svg = _resolveComponent("Svg")!
  const _component_Avatar = _resolveComponent("Avatar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.ticketClass, "card"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Badge, { text: _ctx.prefix }, null, 8, ["text"]),
      _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createVNode(_component_Svg, {
            src: "svg/tickets/ticket_files.svg",
            size: "4"
          }),
          _createElementVNode("span", _hoisted_4, _toDisplayString(Math.floor(Math.random() * 9) + 1), 1)
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_Svg, {
            src: "svg/tickets/ticket_comment.svg",
            size: "2"
          }),
          _createElementVNode("span", _hoisted_5, _toDisplayString(Math.floor(Math.random() * 9) + 1), 1)
        ]),
        (_ctx.assign)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_Avatar, {
                firstName: _ctx.assign.firstName,
                lastName: _ctx.assign.lastName,
                size: 30
              }, null, 8, ["firstName", "lastName"])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}