import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e4d8d18"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header border-0 pt-6" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "d-flex align-items-center position-relative my-1" }
const _hoisted_5 = { class: "svg-icon svg-icon-1 position-absolute ms-6" }
const _hoisted_6 = {
  key: 0,
  class: "card-body pt-0"
}
const _hoisted_7 = { class: "text-dark fs-6 fw-normal" }
const _hoisted_8 = {
  key: 0,
  class: "text-dark fs-6 fw-normal"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, [
            _createVNode(_component_inline_svg, { src: "svg/icons/gen021.svg" })
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
            class: "form-control form-control-solid w-250px ps-15",
            placeholder: "Search platform"
          }, null, 512), [
            [_vModelText, _ctx.search]
          ])
        ])
      ])
    ]),
    (_ctx.allTickets && _ctx.ticketsCount)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_DataTable, {
            tableData: _ctx.allTickets,
            "table-header": _ctx.tableHeader,
            "enable-items-per-page-dropdown": true,
            total: _ctx.ticketsCount,
            onItemsPerPageChange: _ctx.setParamsTicket
          }, {
            "cell-key": _withCtx(({ row: ticket }) => [
              _createVNode(_component_Badge, {
                text: ticket.key
              }, null, 8, ["text"])
            ]),
            "cell-title": _withCtx(({ row: ticket }) => [
              _createElementVNode("span", _hoisted_7, _toDisplayString(ticket.title), 1)
            ]),
            "cell-dueDate": _withCtx(({ row: ticket }) => [
              (ticket.dueDate)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(ticket.dueDate.substring(0, 10)), 1))
                : _createCommentVNode("", true)
            ]),
            "cell-membre": _withCtx(({ row: ticket }) => [
              (ticket && ticket.assignedUser)
                ? (_openBlock(), _createBlock(_component_Avatar, {
                    key: 0,
                    firstName: ticket.assignedUser.firstName,
                    lastName: ticket.assignedUser.lastName,
                    size: 35,
                    class: "ms-2"
                  }, null, 8, ["firstName", "lastName"]))
                : _createCommentVNode("", true)
            ]),
            "cell-status": _withCtx(({ row: ticket }) => [
              _createElementVNode("span", {
                class: _normalizeClass([`badge-light-${ticket.status.order}`, "badge fs-7 fw-normal"])
              }, _toDisplayString(ticket.status.name), 3)
            ]),
            "cell-view": _withCtx(({ row: ticket }) => [
              _createVNode(_component_router_link, {
                to: { name: 'ticketDetails', params: { ticketKey: ticket.key } },
                class: "btn btn-sm btn-light text-dark fw-normal",
                "data-kt-menu-trigger": "click",
                "data-kt-menu-placement": "bottom-end",
                "data-kt-menu-flip": "top-end"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("view")), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]),
            _: 1
          }, 8, ["tableData", "table-header", "total", "onItemsPerPageChange"])
        ]))
      : _createCommentVNode("", true)
  ]))
}