
import { defineComponent, ref, onMounted, computed } from "vue";
import DataTable from "@/components/shared/Datatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/config";
import useTranslate from "@/core/composable/useTranslate";
import { useStore } from "vuex";
import { setParamsTicket } from "@/core/helpers/ticket";
import { useRoute } from "vue-router";
import Badge from "@/components/shared/Badge.vue";
import Avatar from "@/components/shared/Avatar.vue";
interface Pagination {
  platformId: number;
  page: number;
  rowsPerPage: number;
}
export default defineComponent({
  name: "tickets-listing",
  components: {
    DataTable,
    Badge,
    Avatar,
  },
  setup() {
    const checkedTickets = ref([]);
    const { translate } = useTranslate();
    const store = useStore();
    const route = useRoute();
    const platformID = route.params["id"] as string;

    const allTickets = computed(() => {
      return store.getters.listAllTickets.data;
    });
    const ticketsCount = computed(() => {
      return store.getters.listAllTickets.options.totalCount;
    });

    const pagination = ref<Pagination>({
      platformId: parseInt(platformID),
      page: 1,
      rowsPerPage: parseInt(window.localStorage.getItem("limit") || "5"),
    });

    const tableHeader = ref([
      {
        name: ` ${translate("TicketKEY")} `,
        key: "key",
        sortable: true,
      },
      {
        name: ` ${translate("Title")} `,
        key: "title",
        sortable: true,
      },

      {
        name: ` ${translate("dueDate")} `,
        key: "dueDate",
        sortable: true,
      },
      {
        name: ` ${translate("membre")} `,
        key: "membre",
        sortable: true,
      },
      {
        name: ` ${translate("status")} `,
        key: "status",
        sortable: true,
      },
      {
        name: ` ${translate("view")} `,
        key: "view",
      },
    ]);
    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Platform Listing", [
        "Platform",
        "All Platforms",
      ]);
      setParamsTicket(pagination.value);
    });

    const deleteFewTickets = () => {
      checkedTickets.value.forEach((item) => {
        deleteTicket(item);
      });
      checkedTickets.value.length = 0;
    };

    const deleteTicket = (id) => {
      for (let i = 0; i < allTickets.value.length; i++) {
        if (allTickets.value[i].id === id) {
          allTickets.value.splice(i, 1);
        }
      }
    };

    const search = ref<string>("");

    return {
      tableHeader,
      deleteTicket,
      search,
      checkedTickets,
      deleteFewTickets,
      allTickets,
      ticketsCount,
      setParamsTicket,
    };
  },
});
