import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["svg-icon", `svg-icon-${_ctx.size}`])
  }, [
    _createVNode(_component_inline_svg, { src: _ctx.src }, null, 8, ["src"])
  ], 2))
}